import React from "react";
import { Link } from "react-router-dom";

const AboutFive = () => {
  return (
    <div className="section-padding-equal">
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-7">
            <div className="about-team">
              <div className="thumbnail mb-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/about-image.jpg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-team">
              <div className="section-heading heading-left">
                <h2>About Us</h2>
                <p>
                  DTOR provides flexible short term lease and co-space retail
                  model aimed at helping D2C brands accelerate their offline
                  journey, which is often hindered by high capex, long-term
                  leases, and the challenge of securing spaces in high-footfall
                  areas. Our flexible setup options - ranging from three to six
                  months or longer - enabling brands to establish a physical
                  presence without the burden of long-term commitments or
                  significant capital investment.
                </p>
                <p>
                  With DTOR D2C brands can go offline with no risk
                  and low investment.
                </p>
                <Link
                  to="/join"
                  className="axil-btn btn-large btn-fill-primary"
                >
                  Join waitlist
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
