import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "Choose a DTOR Store",
    para: (
      <>
        We take care of all the hassles of opening DTOR Stores at{" "}
        <span style={{ fontWeight: 700, color: "white" }}>
          prime locations{" "}
        </span>
        . Select from our curated list of prime retail DTOR showrooms.
      </>
    ),
  },
  {
    id: 2,
    title: "Flexible Leasing Options",
    para: (
      <>
        Choose a DTOR Store and{" "}
        <span style={{ fontWeight: 700, color: "white" }}>lease duration </span>{" "}
        that suits your needs, whether it's{" "}
        <span style={{ fontWeight: 700, color: "white" }}> 3 or 6 months </span>
        .
      </>
    ),
  },
  {
    id: 3,
    title: "Plug & Play",
    para: (
      <>
        Just{" "}
        <span style={{ fontWeight: 700, color: "white" }}>
          {" "}
          display your products{" "}
        </span>
        , and we handle everything else – from{" "}
        <span style={{ fontWeight: 700, color: "white" }}>
          store setup to staffing{" "}
        </span>{" "}
        – for a seamless, hassle-free experience.
      </>
    ),
  },
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--70 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle=""
          title="How to get started with DTOR?"
          description=""
          textAlignment="heading-left heading-light-left mb--50"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-1.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
