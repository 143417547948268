import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import ServiceTwo from "./pages/ServiceTwo";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import AboutUs from "./pages/AboutUs";
import CustomContact from "./component/contact/CustomContact";

// Css Import
import "./assets/scss/app.scss";

const App = () => {
  useEffect(() => {
    document.querySelector("body").classList.toggle("active-dark-mode");
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<ServiceTwo />} />
          <Route
            path={process.env.PUBLIC_URL + "/join"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            element={<CustomContact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/about"}
            element={<AboutUs />}
          />
          <Route path={process.env.PUBLIC_URL + "*"} element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
