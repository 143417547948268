import React from "react";
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";

const Contact = () => {
  return (
    <>
      <SEO title="DTOR | Join Waitlist" />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne
          title="Join Waitlist <br /> <span style='font-size: 22px; font-weight: 200'> Bookings have surpassed expectations! To manage demand,
                    we've opened a waitlist. Join now and our team will reach
                    out within 12 hours with updates on availability.</span>"
          page="Join"
        />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="" style={{ fontSize: "20px" }}>
                    Please fill your details
                  </h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">Phone</h4>
                  <h4 className="phone-number">
                    <a href="tel:+91-8178511038">+91-8178511038</a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Email</h4>
                  <h4 className="phone-number">
                    <a href="mailto:contact@dtor.in">contact@dtor.in</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Contact;
