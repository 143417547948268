import React from "react";

const Hero = () => {
  return (
    <div class="bg-dark text-light container mt-1 py-5">
      <div class="row flex-lg-row-reverse align-items-center g-5">
        <div class="col-10 mx-auto col-sm-8 col-lg-6">
          <img
            src="/images/others/live-shopping.jpg"
            class="d-block mx-lg-auto img-fluid"
            alt="illustration"
            loading="lazy"
            width={450}
          />
        </div>
        <div class="col-lg-6">
          <div class="lc-block mb-3">
            <div editable="rich">
              <h2 class="fw-bold display-5">1-1 Live Video Shopping Setup</h2>
            </div>
          </div>

          <div class="lc-block mb-3">
            <div editable="rich">
              <p>
                Enhance your customer experience with DTOR's
                <b style={{ fontWeight: 700 }}> Live Video Shopping! </b> We
                provide a seamless setup and link for social media shoppers to
                connect with your salesperson at DTOR via live video calls, {""}
                <b style={{ fontWeight: 700 }}>
                  bringing your store to their screens
                </b>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
