import axios from "axios";
import React, { useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      We have received your details! We will reach to you within 12 hours
    </Alert>
  );
};

const FormTwo = () => {
  const form = useRef();

  const [result, showresult] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const sendUserDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      name: form.current["contact-name"].value,
      email: form.current["contact-email"].value,
      phoneNumber: form.current["contact-phone"].value,
      brandName: form.current["brand-name"].value,
      brandWebsite: form.current["brand-website"].value,
      enquiry: form.current["enquiry"].value,
      interestedLocations: selectedLocations.map((loc) => loc.value),
      secret: "dtor-request-secret",
    };

    try {
      const data = await axios.post(
        "https://dtor-backend.vercel.app/join-waitlist",
        body
      );
      if (data) {
        showresult(true);
        setSelectedLocations([]);
        form.current.reset();
      }
    } catch (error) {
      alert("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { value: "delhi", label: "Delhi" },
    {
      value: "gurgoan",
      label: "Gurgoan",
    },
    { value: "chandigarh", label: "Chandigarh" },
    { value: "mumbai", label: "Mumbai" },
    { value: "bangalore", label: "Bangalore" },
    { value: "jaipur", label: "Jaipur" },
    { value: "ahemdabad", label: "Ahemdabad" },
    { value: "pune", label: "Pune" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#343a40", // Dark background
      borderColor: "#495057", // Darker border
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#343a40", // Dark dropdown
      color: "#fff", // White text
      zIndex: 99,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#495057"
        : state.isFocused
        ? "#6c757d"
        : "#343a40", // Darker when selected or focused
      color: "#fff",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#6c757d", // Dark background for selected values
      color: "#fff",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fff", // White text for selected labels
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#fff",
      ":hover": {
        backgroundColor: "#495057",
        color: "#fff",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff", // White text for input
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ced4da", // Light gray for placeholder
    }),
  };

  return (
    <form ref={form} onSubmit={sendUserDetails} className="axil-contact-form">
      <div className="form-group">
        <label>Name *</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          required
        />
      </div>
      <div className="form-group">
        <label>Contact Email *</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          required
        />
      </div>
      <div className="form-group">
        <label>Contact Phone Number *</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Brand Name *</label>
        <input
          type="text"
          className="form-control"
          name="brand-name"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Brand Website</label>
        <input type="text" className="form-control" name="brand-website" />
      </div>
      <div className="form-group mb--40">
        <label>Interested Locations *</label>
        <Select
          isMulti
          name="interested-locations"
          options={options}
          styles={customStyles}
          classNamePrefix="react-select"
          placeholder="Select Locations"
          value={selectedLocations}
          onChange={(selectedOptions) => setSelectedLocations(selectedOptions)}
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Enquiry</label>
        <textarea className="form-control" name="enquiry" rows="4"></textarea>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
          disabled={loading}
        >
          Join Waitlist
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;
