import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ContactLocation from "../contact/ContactLocation";

const Loacations = () => {
  return (
    <main>
      <div className="section section-padding bg-color-dark overflow-hidden">
        <div className="container">
          <SectionTitle
            subtitle=""
            title="Opening Soon at"
            description=""
            textAlignment="heading-light-left"
            textColor=""
          />
          <div className="row">
            <ContactLocation />
          </div>
        </div>
        <ul className="shape-group-11 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
              alt="line"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
              alt="line"
            />
          </li>
        </ul>
      </div>
    </main>
  );
};

export default Loacations;
