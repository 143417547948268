import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutFive from "../component/about/AboutFive";

const AboutUs = () => {
  return (
    <>
      <SEO title="DTOR | About us" />
      <main className="main-wrapper">
        <HeaderOne />
        <AboutFive />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
