import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram } from "react-icons/fa";

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h5>Contact Us</h5>
                <address>
                  <p>
                    Phone: +91-8178511038
                    <br />
                    Email: contact@dtor.in
                  </p>
                </address>
              </div>
              <div className="col-md-4">
                <h5>Follow Us On </h5>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/dtor.in?igsh=MTY2NDF5bHgxNmE3NA%3D%3D&utm_source=qr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="h5" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/dtor/posts/?feedView=all"
                      _target="blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin className="h5" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <Link to="/">DTOR</Link>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
