import Button from "react-bootstrap/Button";
import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li>
        <li style={{ borderBottom: "none" }}>
          <Button
            variant="primary"
            style={{ backgroundColor: "#5956e9", fontWeight: 500 }}
          >
            <Link to={"/join"}>Join Waitlist</Link>
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
