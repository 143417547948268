import React from "react";
import HeaderOne from "../../common/header/HeaderOne";
import BreadCrumbOne from "../../elements/breadcrumb/BreadCrumbOne";
import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";

const CustomContact = () => {
  return (
    <>
      <SEO title="DTOR | Contact Us" />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Contact" page="Contact" />

        <div className="section section-padding">
          <div className="container">
            <div className="d-flex justify-content-around align-items-center flex-wrap">
              <div className="contact-info mb_md--30 mt_md--0">
                <h4 className="title">Phone</h4>
                <h4 className="phone-number">
                  <a href="tel:+91-8178511038">+91-8178511038</a>
                </h4>
              </div>
              <div className="contact-info">
                <h4 className="title">Email</h4>
                <h4 className="phone-number">
                  <a href="mailto:contact@dtor.in">contact@dtor.in</a>
                </h4>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CustomContact;
